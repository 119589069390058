.maintainance-modal .modal-dialog-centered {
    box-shadow: 0px 8px 16px rgba(189, 195, 199, 0.3); 
    border: 2px solid #BDC3C7; 
    border-radius: 10px;
}


.popup-content {
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 15px;
    text-align: center;
    max-width: 600px;
    font-weight: bold;
    width: 100%;
    position: relative;
    animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 600px) {
    .popup-content {
        padding: 5px 5px; 
        max-width: 100%; 
    }
    .maintainance-modal{
     margin-top: 15vh !important;
    }
    
}

.fade {
    transition: opacity .15s linear;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popup-content h1 {
    margin-bottom: 5px;
    color: #085dab;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .popup-content h1 {
        font-size: 20px;
    }
}

.popup-content h1::before {
    content: "⚠️";
    font-size: 25px;
    margin-right: 5px;
}

@media (max-width: 600px) {
    .popup-content h1::before {
        font-size: 20px;
        margin-right: 5px;
    }
}

.popup-content p {
    font-size: 15px;
    color: #444;
    line-height: 1.6;
    margin-bottom: 5px;
}

@media (max-width: 600px) {
    .popup-content p {
        font-size: 13px;
        margin-bottom: 5px;
    }
}

.popup-content .maintenance-icon {
    font-size: 50px;
    color: #085dab;
    margin-bottom: 5px;
    animation: spin 6s linear infinite;
}

@media (max-width: 600px) {
    .popup-content .maintenance-icon {
        font-size: 35px; 
        margin-bottom: 5px;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
#maintainance-modal{
    z-index: 99999999999999;
}
.popup-content button {
    padding: 7px 7px;
    background-color: #085dab;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.2s;
}

@media (max-width: 600px) {
    .popup-content button {
        padding: 7px 7px;
        font-size: 12x; 
    }
}

.popup-content button:hover {
    background-color: #064a88;
    transform: scale(1.05);
}

.deployment-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.deployment-icons {
    position: relative;
}

.deployment-icon-wrapper {
    position: relative;
    width: 40px; 
    height: 40px;
}

.deployment-gear {
    position: absolute;
    width: 40px; 
    height: 40px; 
    fill: #D3D3D3; 
}

.deployment-gear1 {
    top: 0;
    left: 0;
    filter: contrast(0.9) brightness(0.8) blur(0.2px);
    animation: deployment-rotateClockwise 4s linear infinite;
}

.deployment-gear2 {
    top: 7px; 
    left: 7px; 
    filter: contrast(1.2) brightness(1.1);
    animation: deployment-rotateCounterClockwise 4s linear infinite;
}

.deployment-text {
    font-size: 16px;
    color: #333; 
    text-align: left;
}

@keyframes deployment-rotateClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes deployment-rotateCounterClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
